<template>
  <div v-if="chartOptions">
    <div class="row">
      <div class="col-xs-6">
        <div
          class="form-group form-group-sm no-select"
          style="padding-top:2px;"
        >
          <label class="checkbox-inline">
            <input type="checkbox" v-model="custom" />
            {{ $tc("customized", 2) }}
          </label>
        </div>
      </div>
      <div class="col-xs-6" v-if="custom">
        <div class="form-group form-group-sm no-select">
          <div class="input-group">
            <div
              class="input-group-addon btn"
              @click.stop.prevent="add"
              :title="$tc('add', 1)"
              style="padding: 6px;"
            >
              <i class="fa fa-plus"></i>
            </div>
            <select
              class="form-control no-padding text-center"
              v-model="currentIndex"
              style="min-width: 32px;"
            >
              <option
                v-for="(item, index) in list"
                :key="index"
                :value="index"
                :selected="index == currentIndex"
              >
                {{ index + 1 }}
              </option>
            </select>
            <div
              class="input-group-addon btn"
              @click.stop.prevent="remove"
              :title="$tc('remove', 1)"
              style="padding: 6px;"
            >
              <i class="fa fa-minus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="custom">
      <ChartYAxisForm
        ref="chartYAxisForm"
        v-if="currentIndex >= 0"
        v-model="axisConfig"
        :key="currentIndex"
        :namedQuery="namedQuery"
      >
      </ChartYAxisForm>
    </template>
  </div>
</template>

<script>
import ChartYAxisForm from "@/components/control-sidebar/property-editors/chart-axis-y-form.vue";
import {
  dftYAxis,
  axisProp
} from "@/components/control-sidebar/property-editors/chart-axis-y-form.vue";
export default {
  name: "ChartAxisYListForm",
  components: {
    ChartYAxisForm
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true
    },
    namedQuery: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    return {
      iCustom: false,
      currentIndex: 0,
      chartOptions: null
    };
  },
  computed: {
    list() {
      return this.custom ? this?.chartOptions?.yAxis : [];
    },
    custom: {
      set(value) {
        if (!this.chartOptions) return;
        this.iCustom = value;
        this.currentIndex = 0;
        this.$set(this.chartOptions, "yAxis", [dftYAxis()]);
        this.$set(this.chartOptions, "customYAxis", this.iCustom);
        this.$emit("input", this.chartOptions);
        this.focus();
      },
      get() {
        return this.iCustom;
      }
    },
    axisConfig: {
      set(value) {
        if (!value) return;
        let axis = {
          ...((this.chartOptions.yAxis || [])[this.currentIndex] || {}),
          ...value
        };
        this.$set(this.chartOptions.yAxis, this.currentIndex, axis);
        this.$emit("input", this.chartOptions);
      },
      get() {
        return (this?.chartOptions?.yAxis || [null])[this.currentIndex];
      }
    }
  },
  watch: {
    currentIndex(n, o) {
      if (n != o) {
        this.focus();
      }
    }
  },
  methods: {
    add() {
      let yAxis = this.chartOptions.yAxis || [];
      this.currentIndex = yAxis.length;
      this.$set(
        yAxis,
        this.currentIndex,
        dftYAxis({position: this.currentIndex == 0 ? "left" : "right"})
      );
      this.$emit("input", this.chartOptions);
    },
    remove() {
      let yAxis = this.chartOptions.yAxis || [];
      this.$delete(yAxis, this.currentIndex);
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.$nextTick(this.add);
        return;
      }
      this.$emit("input", this.chartOptions);
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.chartYAxisForm && this.$refs.chartYAxisForm.focus();
      });
    }
  },
  created() {
    this.chartOptions = structuredClone(this.value);
    this.iCustom = this?.chartOptions?.customYAxis ?? false;
    if (this?.chartOptions?.yAxis && !Array.isArray(this.chartOptions.yAxis)) {
      // BEGIN backward compatibility
      // The "customYAxis" property might not exist yet on chart definition
      if (!this.iCustom) {
        this.iCustom = ["min", "max", "interval", "inverse"].some(
          (i) => axisProp(null, this.chartOptions.yAxis, i) !== ""
        );
      }
      // END backward compatibility

      // It make it compatible with the new yAxis that are represented by Array instead of Object
      this.chartOptions.yAxis = [this.chartOptions.yAxis];
    }
  }
};
</script>

<style scoped></style>
